<template>
  <div class="detailedInformation-container">
    <div class="nav container">
      <img src="../../assets/images/weizi.png" class="icon-img" />
      <el-breadcrumb separator=">" style="display: inline-block">
        <el-breadcrumb-item class="navC" @click="navBreak">
          <span style="color: #999999 !important">园区资讯</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item
          ><span style="color: #000000 !important">{{
            name
          }}</span></el-breadcrumb-item
        >
      </el-breadcrumb>
      <div class="bor"></div>
    </div>
    <div class="tag container">
      {{ list.title }}
      <div :class="{ 'tag-con': list.address }">
        <div>发布时间：{{ list.uploadTime || list.updateTime }}</div>
        <div v-if="list.address">来源：{{ list.address }}</div>
      </div>
    </div>
    <div class="bor" style="margin-top: -14px"></div>
    <div
      class="container"
      v-html="list.content || list.text"
      style="font-size: 20px; color: #555"
    ></div>
    <div
      class="bor"
      style="margin-top: 30px"
      v-if="list.content || list.text"
    ></div>
    <div class="zip container">
      <div
        class="fujian"
        v-if="
          fileUrlList != null && fileUrlList.length > 0 && fileUrlList[0] !== ''
        "
      >
        附件
      </div>
      <template
        v-if="
          fileUrlList != null && fileUrlList.length > 0 && fileUrlList[0] !== ''
        "
      >
        <div v-for="item in fileUrlList" :key="item">
          <div v-if="item !== ''" style="margin-top: 10px">
            <a :href="item" class="xiazai">{{ fileName(item) }}</a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import mitt from "@/utils/mitt";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const fileUrlList = ref([]);
    const name = ref("");
    const list = ref({});
    list.value = JSON.parse(route.query.it);
    name.value = route.query.name;
    let page = route.query.page
    fileUrlList.value = list.value.fileUrl?.split(",");
    const fileName = (str) => {
      let strArr = str.split("/");
      let index = strArr.length - 1;
      return strArr[index];
    };
    const navBreak = () => {
      mitt.emit('pgesNav',page)
      router.back();
    };
    return {
      list,
      name,
      fileUrlList,
      fileName,
      navBreak,
    };
  },
};
</script>

<style scoped>
.navC {
  cursor: pointer;
}
.tag-con {
  display: flex;
  justify-content: space-between;
}
.detailedInformation-container {
  /* width: 1920px; */
  width: 100%;
  margin: 0 auto;
}
.el-footer{

}
.icon-img {
  width: 12px;
  height: 18px;
  margin-right: 10px;
  vertical-align: 0.2rem;
}
.fujian {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 33px;
  color: #000000;
  opacity: 1;
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
  margin-top: 10px;
}
.fujian::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 24px;
  background: linear-gradient(180deg, #999999 0%, #000000 100%);
  left: 2px;
  top: 6px;
}

.bor {
  max-width: 1420px;
  height: 1px;
  background: rgba(0, 0, 0, 0.39);
  opacity: 1;
  margin: 0 auto;
  margin-top: 5px;
}
.container {
  max-width: 1420px;
  margin: 30px auto 30px auto;
}
.tag {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 33px;
  color: #000000;
  opacity: 1;
  text-align: center;
}
.tag div {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 25px;
  color: #999999;
  opacity: 1;
  text-align: left;
  margin-top: 30px;
}
.pD {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #555;
  opacity: 1;
  text-indent: 2rem;
  margin: 0;
  line-height: 28px;
}
.imgDiv {
  width: 800px;
  height: 540px;
  margin: 0 auto;
  margin-top: 20px;
}
.imgDiv img {
  width: 100%;
  height: 100%;
}
.xiazai {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 28px;
  color: #1dafff;
  opacity: 1;
  cursor: pointer;
  text-decoration: underline;
}
</style>
